
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

export default defineComponent({
  name: "kt-widget-2",
  components: {
    Dropdown1,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const items = [
      {
        image: "media/svg/brand-logos/plurk.svg",
        info: {
          title: "Top Authors",
          description: "Successful Fellas",
        },
        badges: [
          { label: "Angular", color: "danger" },
          { label: "PHP", color: "info" },
        ],
        remarks: "4600 Users",
      },

      {
        image: "media/svg/brand-logos/telegram.svg",
        info: {
          title: "Popular Authors",
          description: "Most Successful",
        },
        badges: [
          { label: "HTML", color: "danger" },
          { label: "CSS", color: "info" },
        ],
        remarks: "7200 Users",
      },

      {
        image: "media/svg/brand-logos/vimeo.svg",
        info: {
          title: "New Users",
          description: "Awesome Users",
        },
        badges: [
          { label: "React", color: "danger" },
          { label: "SASS", color: "info" },
        ],
        remarks: "890  Users",
      },

      {
        image: "media/svg/brand-logos/bebo.svg",
        info: {
          title: "Active Customers",
          description: "Best Customers",
        },
        badges: [
          { label: "Java", color: "danger" },
          { label: "PHP", color: "info" },
        ],
        remarks: "6370 Users",
      },

      {
        image: "media/svg/brand-logos/kickstarter.svg",
        info: {
          title: "Bestseller Theme",
          description: "Amazing Templates",
        },
        badges: [
          { label: "Python", color: "danger" },
          { label: "MySQL", color: "info" },
        ],
        remarks: "354 Users",
      },
    ];

    return {
      items,
    };
  },
});
